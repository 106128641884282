// 商保
export const commercialRouter = [
  {
    path: '/commercialInsurance/addOrder',
    name: 'ExchangeManagement',
    component: () => import('../views/commercialInsurance/addOrder')
  },
  {
    path: '/commercialInsurance/packageMng',
    name: 'packageMng',
    component: () => import('../views/commercialInsurance/packageMng')
  },
  {
    path: '/commercialInsurance/productList',
    name: 'productList',
    component: () => import('../views/commercialInsurance/productList')
  },
  {
    path: '/commercialInsurance/seePackage/:id',
    name: 'seePackage',
    component: () => import('../views/commercialInsurance/seePackage'),
  },
  {
    path: '/commercialInsurance/orderMng',
    component: () => import('../views/commercialInsurance/orderMng'),
    meta: {title: '订单管理'},
  },
  {
    path: '/commercialInsurance/seeOrderDetail/:id',
    component: () => import('../views/commercialInsurance/seeOrderDetail'),
    meta: {title: '订单详情'},
  },
  {
    path: '/commercialInsurance/comInsuranceMng',
    component: () => import('../views/commercialInsurance/comInsuranceMng'),
    meta: {title: '商保申报管理'},
  },
  {
    path: '/commercialInsurance/insuredPersonnelList',
    component: () => import('../views/commercialInsurance/insuredPersonnelList'),
    meta: {title: '参保人员列表'},
  },
]