// 员工学习
export const employeeLearnRouter = [
  // 套餐设置
  {
    path: '/employeeLearn/packageSet',
    name: 'packageSet',
    component: () => import('../views/employeeLearn/packageSet')
  },
  // 订单管理
  {
    path: '/employeeLearn/orderMng',
    name: 'orderMng',
    component: () => import('../views/employeeLearn/orderMng')
  },
  // 客户数据
  {
    path: '/employeeLearn/customerData',
    name: 'customerData',
    component: () => import('../views/employeeLearn/customerData')
  },
  // 课程统计
  {
    path: '/employeeLearn/courseStatistics',
    name: 'courseStatistics',
    component: () => import('../views/employeeLearn/courseStatistics')
  },
]