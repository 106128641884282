let baseUrl = '';
let preUrl = "";
if (process.env.NODE_ENV == 'development') {
  //开发环境
  baseUrl = 'https://api.ers-business.dev-01.54test.cn';
  preUrl = 'https://api.ers-commercial.dev-01.54test.cn';
} else if (process.env.NODE_ENV == 'qa') {
  //测试环境
  baseUrl = 'https://api.ers-business.test-01.54test.cn';
  preUrl = 'https://api.ers-commercial.test-01.54test.cn';
} else if (process.env.NODE_ENV == 'production') {
  //正式环境
  baseUrl = 'https://busapi.1renshi.com';
  preUrl = 'https://api.commercial.1renshi.com';
}
// 导出baseUrl
export {baseUrl,preUrl};
