// 用户中心
export const appCenterRouter = [
  {
    path: '/EvaluationLabelMng',
    component: () => import('../views/appCenter/EvaluationLabelMng'),
    redirect: '/appCenter/EvaluationLabelMng',
    meta: {},
    children: [
      {
        path: '/appCenter/EvaluationLabelMng',
        name: 'EvaluationLabelMng',
        component: () => import('../views/appCenter/EvaluationLabelMng')
      }
    ]
  },
  // 编辑标签
  {
    path: '/EditEvaLabel',
    component: () => import('../views/appCenter/EditEvaLabel'),
    redirect: '/appCenter/EvaluationLabelMng',
    meta: {},
    children: [
      {
        path: '/appCenter/EditEvaLabel/:id',
        name: 'EditEvaLabel',
        component: () => import('../views/appCenter/EditEvaLabel')
      }
    ]
  },
]