// 系统 
export const systemRouter = [
  //  hrMenu 新增、编辑
  {
    path: '/system/AddOrEditHrMenu',
    name: 'AddOrEditHrMenu',
    component: () => import('../views/system/AddOrEditHrMenu')
  },
  // 社保预收明细表
  {
    path: '/system/SocialAdvanceDetailList',
    name: 'SocialAdvanceDetailList',
    component: () => import('../views/system/SocialAdvanceDetailList')
  }
];
