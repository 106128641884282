export const insuranceTrustRouter = [
  //  社保托管
  // 初始在保员工管理
  {
    path: '/InsuranceTrust/initialEmployeeMng',
    name: 'initialEmployeeMng',
    component: () => import('../views/InsuranceTrust/initialEmployeeMng')
  },
  {
    path: '/InsuranceTrust/initialEmployeeDel',
    name: 'initialEmployeeDel',
    component: () => import('../views/InsuranceTrust/initialEmployeeDel')
  },
];