// 智能入职
export const smartRouter = [
  {
    path: '/smartEntry/DataStatistics',
    name: 'SmartEntryIndex',
    component: () => import('../views/smartEntry/DataStatistics')
  },
  {
    path: '/smartEntry/orderMng',
    name: 'OrderMng',
    component: () => import('../views/smartEntry/OrderMng')
  },
  {
    path: '/smartEntry/packageMng',
    name: 'packageMng',
    component: () => import('../views/smartEntry/PackageMng')
  },
  {
    path: '/socialMng/policyReview',
    name: 'PolicyReview',
    component: () => import('../views/SocialMng/PolicyReview')
  }
]