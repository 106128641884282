// 积分商城
export const pointsMallRouter = [
  // 员工服务app账户管理
  {
    path: '/pointsMall/EmSvAppAccountMng',
    name: 'EmSvAppAccountMng',
    component: () => import('../views/pointsMall/EmSvAppAccountMng')
  },
  // 员工积分账户
  {
    path: '/pointsMall/FyEmpPointsAccount',
    name: 'FyEmpPointsAccount',
    component: () => import('../views/pointsMall/FyEmpPointsAccount')
  },
  // 员工积分账户--详情
  {
    path: '/pointsMall/FyEmpPointsAccountDtl',
    name: 'FyEmpPointsAccountDtl',
    component: () => import('../views/pointsMall/FyEmpPointsAccountDtl')
  },
  // 企业积分账户
  {
    path: '/pointsMall/FyPointsAccount',
    name: 'FyPointsAccount',
    component: () => import('../views/pointsMall/FyPointsAccount')
  },
  // 企业积分账户--详情
  {
    path: '/pointsMall/FyPointsAccountDtl',
    name: 'FyPointsAccountDtl',
    component: () => import('../views/pointsMall/FyPointsAccountDtl')
  },
  // 积分福利结算报表 详情
  {
    path: '/pointsMall/PointsBenefitsSettRepo',
    name: 'PointsBenefitsSettRepo',
    component: () => import('../views/pointsMall/PointsBenefitsSettRepo')
  },
  // 充值订单管理
  {
    path: '/pointsMall/RechargeOrderMng',
    name: 'RechargeOrderMng',
    component: () => import('../views/pointsMall/RechargeOrderMng')
  },
  // 积分充值记录
  {
    path: '/pointsMall/FyPointsRechargeRecord',
    name: 'FyPointsRechargeRecord',
    component: () => import('../views/pointsMall/FyPointsRechargeRecord')
  },
  // 积分商城订单管理
  {
    path: '/pointsMall/FyPointsMallOrderMng',
    name: 'FyPointsMallOrderMng',
    component: () => import('../views/pointsMall/FyPointsMallOrderMng')
  },
  // 积分商城订单管理 --详情
  {
    path: '/pointsMall/FyPointsMallOrderMngDtl',
    name: 'FyPointsMallOrderMngDtl',
    component: () => import('../views/pointsMall/FyPointsMallOrderMngDtl')
  },
  // 商品列表
  {
    path: '/pointsMall/FyProductList',
    name: 'FyProductList',
    component: () => import('../views/pointsMall/FyProductList')
  },
  // 商品列表-查看
  {
    path: '/pointsMall/FyProductListSee',
    name: 'FyProductListSee',
    component: () => import('../views/pointsMall/FyProductListSee')
  },
  // 商品列表-编辑
  {
    path: '/pointsMall/FyProductListEdit',
    name: 'FyProductListEdit',
    component: () => import('../views/pointsMall/FyProductListEdit')
  },
  // 商品类目
  {
    path: '/pointsMall/FyCategoryList',
    name: 'FyCategoryList',
    component: () => import('../views/pointsMall/FyCategoryList')
  },
  // 积分商城配置管理
  {
    path: '/pointsMall/FyPMConfigMng',
    name: 'FyPMConfigMng',
    component: () => import('../views/pointsMall/FyPMConfigMng')
  },
  // 积分商城配置管理--图片组件 查看、编辑
  {
    path: '/pointsMall/FyPMConfigMngPicSeeOrEdit',
    name: 'FyPMConfigMngPicSeeOrEdit',
    component: () => import('../views/pointsMall/FyPMConfigMngPicSeeOrEdit')
  },
  // 积分商城配置管理--商品列表组件 查看、编辑
  {
    path: '/pointsMall/FyPMConfigMngGoodSeeOrEdit',
    name: 'FyPMConfigMngGoodSeeOrEdit',
    component: () => import('../views/pointsMall/FyPMConfigMngGoodSeeOrEdit')
  },
]