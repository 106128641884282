// 壹豆
export const integralSystemRouter = [
  {
    path: '/integralSystem/UploadMerchandise',
    name: 'UploadMerchandise',
    component: () => import('../views/integralSystem/UploadMerchandise')
  },
  {
    path: '/integralSystem/ExchangeManagement',
    name: 'ExchangeManagement',
    component: () => import('../views/integralSystem/ExchangeManagement')
  },
  {   
    // 抽奖活动列表页
    path: '/integralSystem/ActivityList',
    name: 'ActivityList',
    component: () => import('../views/integralSystem/ActivityList')
  },
  {   
    // 抽奖活动设置页
    path: '/integralSystem/ActivitySet',
    name: 'ActivitySet',
    component: () => import('../views/integralSystem/ActivitySet')
  },
  {   
    // 中奖统计表
    path: '/integralSystem/WinningStatistics',
    name: 'WinningStatistics',
    component: () => import('../views/integralSystem/WinningStatistics')
  },
  {   
    // 题库管理
    path: '/integralSystem/questionMng',
    name: 'questionMng',
    component: () => import('../views/integralSystem/QuestionMng')
  },
];